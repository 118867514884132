// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dyakuemo-js": () => import("./../../../src/pages/dyakuemo.js" /* webpackChunkName: "component---src-pages-dyakuemo-js" */),
  "component---src-pages-feedback-bad-js": () => import("./../../../src/pages/feedback/bad.js" /* webpackChunkName: "component---src-pages-feedback-bad-js" */),
  "component---src-pages-feedback-done-js": () => import("./../../../src/pages/feedback/done.js" /* webpackChunkName: "component---src-pages-feedback-done-js" */),
  "component---src-pages-feedback-good-js": () => import("./../../../src/pages/feedback/good.js" /* webpackChunkName: "component---src-pages-feedback-good-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-publichna-oferta-js": () => import("./../../../src/pages/publichna-oferta.js" /* webpackChunkName: "component---src-pages-publichna-oferta-js" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-kontakty-js": () => import("./../../../src/pages/ru/kontakty.js" /* webpackChunkName: "component---src-pages-ru-kontakty-js" */),
  "component---src-pages-ru-publichna-oferta-js": () => import("./../../../src/pages/ru/publichna-oferta.js" /* webpackChunkName: "component---src-pages-ru-publichna-oferta-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-service-ru-js": () => import("./../../../src/templates/serviceRu.js" /* webpackChunkName: "component---src-templates-service-ru-js" */)
}

