import "./node_modules/bootstrap/scss/bootstrap.scss"
import "./src/styles/global.scss"

// const React = require("react")
// const Header = require("./src/components/Layout/header").default
// import React from "react"
// import Header from "./src/components/Layout/header"

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  // window.onload = function () {
  //   window.BinotelCallTracking[508835].replacePhoneNumbersOnDynamicContent()
  // }
  // if (window.BinotelCallTracking) {
  //   window.BinotelCallTracking[508835].replacePhoneNumbersOnDynamicContent()
  // }
}

// Wraps every page in a component
// export const wrapPageElement = ({ element, props }) => {
//   return <Header {...props}>{element}</Header>
// }
